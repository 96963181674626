import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useProvidersList(emit, root) {
  // Use toast
  const toast = useToast()

  const refProviderListTable = ref(null)
  const blankData = {
    id: null,
    trading_name: '',
    corporate_name: '',
    identification1: '',
    identification2: '',
    email: '',
    contact_name: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    account_plan_id: null,
    observation: '',
    address: {
      zip_code: '',
      city_id: '',
    },
  }
  const provider = ref(JSON.parse(JSON.stringify(blankData)))

  const tableColumns = [
    {
      key: 'trading_name',
      label: 'Nome Fantasia',
      formatter: title,
      sortable: true,
    },
    {
      key: 'corporate_name',
      label: 'Razão Social',
      formatter: title,
      sortable: true,
    },
    {
      key: 'phones',
      label: 'Telefone',
      sortable: false,
    },
    {
      key: 'email',
      label: 'E-mail',
      sortable: false,
    },
    {
      key: 'identification1',
      label: 'CNPJ/CPF',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refProviderListTable.value ? refProviderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refProviderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const editProviderShowModal = ref(false)

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const handleEditProvider = data => {
    provider.value = JSON.parse(JSON.stringify(data))

    if (data.phones) {
      data.phones.map(p => {
        if (p.type === 'MOBILE') provider.value.phoneNumber = p.phone
        if (p.type === 'PHONE') provider.value.secondPhoneNumber = p.phone
        if (p.type === 'OTHER') provider.value.thirdPhoneNumber = p.phone
        return p
      })
    }
    editProviderShowModal.value = true
  }

  const deleteProvider = async data => {
    try {
      await store.dispatch('store-provider/delete', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleDeleteProvider = data => {
    root.$bvModal
      .msgBoxConfirm('Deseja excluir a o fornecedor?', {
        title: 'Por Favor Confirme esta Ação',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(resp => {
        if (resp) deleteProvider(data)
      })
  }

  const fetchProviders = (ctx, callback) => {
    store
      .dispatch('store-provider/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        catchErr(e)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolvePhones = phones => phones.map(phone => phone.phone).join(' | ')

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProviderListTable,
    provider,
    editProviderShowModal,

    handleDeleteProvider,
    handleEditProvider,
    resolvePhones,
    fetchProviders,
    resolveUserStatusVariant,
    refetchData,
  }
}
