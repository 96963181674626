<template>
  <div>
    <b-form>
      <b-row>

        <!-- Field: CEP -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="CEP"
            label-for="zip-code"
          >
            <b-form-input
              id="zip-code"
              v-model="userData.address.zip_code"
              v-mask="['#####-###']"
              @keyup="searchZipCode"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Logradouro -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Logradouro"
            label-for="public-place"
          >
            <v-select
              v-model="userData.address.public_place"
              :options="publicPlaceOptions"
              :reduce="val => val.value"
              :clearable="true"
              :searchable="true"
              input-id="public-place"
              placeholder="Selecione"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Endereço -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Endereço"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="userData.address.street"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Número -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Número"
            label-for="number"
          >
            <b-form-input
              id="number"
              v-model="userData.address.number"
              type="number"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Bairro -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Bairro"
            label-for="district"
          >
            <b-form-input
              id="district"
              v-model="userData.address.neighborhood"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Cidade -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Cidade"
            label-for="address-city"
          >
            <v-select
              v-model="userData.address.city_id"
              :reduce="option => option.value"
              :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'"
              :options="cityOptions"
              input-id="address-city"
            >
              <template #no-options="{}">
                Não encontrado.
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Estado -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Estado"
            label-for="address-state"
          >
            <v-select
              v-model="userData.address.state_id"
              :options="$store.getters['states/getStates']"
              :reduce="val => val.value"
              :clearable="true"
              :searchable="true"
              input-id="address-state"
              placeholder="Selecione"
              @input="fillCity(userData.address.state_id, null, 'cityOptions'), userData.address.city_id = null"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Complemento-->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Complemento"
            label-for="complement"
          >
            <b-form-input
              id="complement"
              v-model="userData.address.complement"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import axios from '@axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    mask,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    store.dispatch('states/fetchStates')

    const publicPlaceOptions = [
      { label: 'Rua', value: 'Rua' },
      { label: 'Avenida', value: 'Avenida' },
      { label: 'Rodovia', value: 'Rodovia' },
      { label: 'Estrada', value: 'Estrada' },
      { label: 'Lote', value: 'Lote' },
      { label: 'Servidão', value: 'Servidão' },
      { label: 'Quadra', value: 'Quadra' },
      { label: 'Travessa', value: 'Travessa' },
      { label: 'Linha', value: 'Linha' },
    ]
    return {
      publicPlaceOptions,
    }
  },
  data() {
    return {
      cityOptions: [],
    }
  },
  mounted() {
    // Seleciona estado e preenche cidades
    if (this.userData.address.state_id) {
      if (this.userData.address.city_id) {
        this.fillCity(this.userData.address.state_id, this.userData.address.city_id)
      } else {
        this.fillCity(this.userData.address.state_id, null)
      }
    }
  },
  methods: {
    async searchZipCode($event) {
      const zipCode = this.userData.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zipCode.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas
        axios.noHeaders = true // enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`)
        if (resp.data.erro) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'O CEP não existe ou não foi encontrado.')
          return
        }

        this.userData.address.neighborhood = resp.data.bairro
        this.userData.address.complement = resp.data.complemento

        // abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.userData.address.public_place = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.userData.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.userData.address.street = resp.data.logradouro
        }

        const state = this.$store.getters['states/getStates'].find(o => o.initials === resp.data.uf)
        this.userData.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade, 'cityOptions', 'tenant')
      }
    },

    async fillCity(stateId, city = null, prop = 'cityOptions') {
      try {
        this[prop] = []
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          resp.data.map(c => this[prop].push({ value: c.id, label: c.city }))

          if (city) {
            // procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            this.userData.address.city_id = ct.value
          }
        }
      } catch (error) {
        this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
      }
    },

    showToast(variant = 'success', icon = 'CheckIcon', title = 'Sucesso', text = 'Dados Salvos com Sucesso.') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
