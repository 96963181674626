<template>

  <div>

    <!-- Modal Cadastrar Fornecedor -->
    <modal-new-provider
      :show="newProviderShowModal"
      @refetchData="refetchData"
      @hidden="newProviderShowModal = false"
    />

    <!-- Modal Editar Fornecedor -->
    <modal-edit-provider
      :show="editProviderShowModal"
      :provider="provider"
      @refetchData="refetchData"
      @hidden="editProviderShowModal = false"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Spacer -->
          <b-col
            cols="12"
            md="10"
          />

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="7"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>

          <!-- Button New Provider -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="outline-primary"
              block
              @click="newProviderShowModal = true"
            >
              <span>Novo Fornecedor</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refProviderListTable"
        class="position-relative tableMinHeght"
        :items="fetchProviders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Phones -->
        <template #cell(phones)="data">
          {{ resolvePhones(data.item.phones) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleEditProvider(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="handleDeleteProvider(data.item)">
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Excluir</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BDropdown, BDropdownItem, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import modalNewProvider from '@/views/apps/providers/modalNewProvider.vue'
import providerStoreModule from '../providerStoreModule'
import useProvidersList from './useProvidersList'

import modalEditProvider from '../modalEditProvider.vue'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    modalNewProvider,
    modalEditProvider,
    vSelect,
  },
  setup(_, { emit, root }) {
    const USER_APP_STORE_MODULE_PROVIDER = 'store-provider'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_PROVIDER)) store.registerModule(USER_APP_STORE_MODULE_PROVIDER, providerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_PROVIDER)) store.unregisterModule(USER_APP_STORE_MODULE_PROVIDER)
    })

    const isAddNewUserSidebarActive = ref(false)
    const newProviderShowModal = ref(false)

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProviderListTable,
      provider,
      editProviderShowModal,
      handleEditProvider,
      handleDeleteProvider,
      fetchProviders,
      refetchData,

      // UI
      resolvePhones,
      resolveUserStatusVariant,
    } = useProvidersList(emit, root)

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProviderListTable,
      newProviderShowModal,
      editProviderShowModal,
      provider,

      handleEditProvider,
      handleDeleteProvider,
      refetchData,
      fetchProviders,

      // Filter
      avatarText,

      // UI
      resolvePhones,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
