import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'

const addressBlank = {
  street: null,
  public_place: null, // string nullable
  number: null,
  complement: null,
  neighborhood: null,
  zip_code: null,
  observation: null,
  type_address: 'COM',
  city_id: null,
  state_id: null,
}

export default {
  namespaced: true,
  state: {
    provider: {
      phones: [],
    },
  },
  getters: {
    provider: state => {
      if (state.provider && !state.provider.address) {
        state.provider.address = addressBlank
      }

      if (state.provider && (!state.provider.responsibles || !state.provider.responsibles.length)) {
        state.provider.responsible = {
          address: addressBlank,
        }
      } else if (state.provider) {
        [state.provider.responsible] = state.provider.responsibles
        if (!state.provider.responsible.address) state.provider.responsible.address = addressBlank
      }

      if (state.provider && state.provider.phones) {
        // eslint-disable-next-line array-callback-return
        state.provider.phones.map(p => {
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'MOBILE') state.provider.phoneNumber = p.phone
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'PHONE') state.provider.secondPhoneNumber = p.phone
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'OTHER') state.provider.thirdPhoneNumber = p.phone
        })
      }
      return state.provider
    },
  },

  mutations: {
    SET_PROVIDER(state, provider) {
      state.provider = provider
    },
  },

  actions: {
    async fetch(ctx, args) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/providers${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async search(ctx, args) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/provider/search`, args)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    add(ctx, provider) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/provider`, provider)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update({ commit }, provider) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/provider/${provider.id}`, provider)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    delete(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/provider/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
