<template>
  <div>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-new-provider"
        v-model="showLocal"
        centered
        size="lg"
        title="Cadastro de Fornecedor"
        hide-footer
        @hidden="hidden"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >
          <b-form>
            <b-row>

              <!-- Field: Nome Fantasia -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="Nome Fantasia"
                  immediate
                >
                  <b-form-group
                    label="Nome Fantasia"
                    label-for="trading-name"
                  >
                    <b-input-group>
                      <b-form-input
                        id="trading-name"
                        v-model="provider.trading_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Razão Social -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="Razão Social"
                  immediate
                >
                  <b-form-group
                    label="Razão Social"
                    label-for="corporate-name"
                  >
                    <b-input-group>
                      <b-form-input
                        id="corporate-name"
                        v-model="provider.corporate_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: CNPJ -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="`required|cpfCnpj|checkDoubleProviderIdentification:null`"
                  name="CNPJ/CPF"
                  immediate
                >
                  <b-form-group
                    label="CNPJ/CPF"
                    label-for="identification-one"
                  >
                    <b-form-input
                      id="identification-one"
                      v-model="provider.identification1"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Nome do Representante -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Nome do Representante"
                  label-for="contact-name"
                >
                  <b-input-group>
                    <b-form-input
                      id="contact-name"
                      v-model="provider.contact_name"
                      type="text"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Field: E-mail -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email-edit"
                  >
                    <b-form-input
                      id="email-edit"
                      v-model="provider.email"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >

                <!-- Field: Telefone Celular -->
                <validation-provider
                  #default="{ errors }"
                  name="Celuar"
                  rules="required"
                  immediate
                >
                  <!-- MUDAR NO BACKEND PARA TYPE: 'MOBILE' -->
                  <b-form-group
                    label="Celular"
                    label-for="provider-mobile-number"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="provider-mobile-number"
                        v-model="provider.phoneNumber"
                        v-mask="['(##) ####-#####', '(##) # ####-####']"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Telefone Fixo -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Fixo"
                  label-for="contact-phone-1"
                >
                  <!-- MUDAR NO BACKEND PARA TYPE: 'PHONE' -->
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PhoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="contact-phone-1"
                      v-model="provider.secondPhoneNumber"
                      v-mask="['(##) ####-#####', '(##) # ####-####']"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Field: Plano de Contas -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Plano de Contas"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Plano de Contas"
                    label-for="account-plan"
                  >
                    <v-select
                      v-model="provider.account_plan_id"
                      input-id="account-plan"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :options="accountPlans"
                      :clearable="false"
                      :searchable="false"
                      placeholder="Selecione"
                    >
                      <template #no-options="{ }">
                        Nenhum resultado encontrado.
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Este campo é obrigatório
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <!-- Tabs -->
            <b-tabs
              pills
              class="mt-1"
            >

              <!-- Tab Endereço -->
              <b-tab active>
                <template #title>
                  <feather-icon
                    icon="MapPinIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Endereço</span>
                </template>
                <provider-modal-tab-address
                  :user-data="provider"
                />
              </b-tab>

              <!-- Tab Informações Adicionais -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Informações Adicionais</span>
                </template>
                <provider-modal-tab-add-info
                  :data="provider"
                />
              </b-tab>
            </b-tabs>

            <hr>

            <!-- Footer -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              />

              <b-col
                cols="12"
                md="3"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="hidden"
                >
                  Cancelar
                </b-button>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  :disabled="invalid"
                  @click="save"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-overlay>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BOverlay,
  BInputGroupPrepend, BTab, BTabs, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required, email, cpfCnpj,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  toRefs, ref, watch, onUnmounted, computed,
} from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProviderModalTabAddress from './ProviderModalTabAddress.vue'
import ProviderModalTabAddInfo from './ProviderModalTabAddInfo.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BFormInvalidFeedback,
    BButton,
    BOverlay,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    ProviderModalTabAddress,
    ProviderModalTabAddInfo,
  },
  directives: {
    mask,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const USER_APP_STORE_MODULE_ACCOUNT_PLAN = 'store-account-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)) store.registerModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN, accountPlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)) store.unregisterModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)
    })

    const showLocal = ref(false)
    const showOverlay = ref(false)

    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const blankData = {
      id: null,
      trading_name: '',
      corporate_name: '',
      identification1: '',
      identification2: '',
      email: '',
      contact_name: '',
      phoneNumber: '',
      secondPhoneNumber: '',
      account_plan_id: null,
      observation: '',
      address: {
        zip_code: '',
        city_id: '',
      },
    }
    const provider = ref(JSON.parse(JSON.stringify(blankData)))

    const hidden = () => {
      provider.value = JSON.parse(JSON.stringify(blankData))
      emit('hidden')
    }

    store.dispatch('store-account-plan/fetch')
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('3 - DESPESAS'))

    const toast = useToast()
    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          titleToast,
          icon,
          text,
          variant,
        },
      },
      {
        timeout: 8000,
      })
    }

    const save = async () => {
      try {
        showOverlay.value = true
        await store.dispatch('store-provider/add', provider.value)
        emit('refetchData')
        showToast()
        hidden()
        showOverlay.value = false
      } catch (e) {
        showOverlay.value = false
        if (e.response.status === 412) {
          showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
        } else {
          showToast('danger', 'AlertCircleIcon', 'Atenção', e.message)
        }
      }
    }

    return {
      showLocal,
      required,
      email,
      cpfCnpj,
      accountPlans,
      provider,
      showOverlay,
      hidden,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
