<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Informações Adicionais -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Informações Adicionais"
            label-for="provider-additional-info"
          >
            <b-form-textarea
              id="provider-additional-info"
              v-model="data.observation"
              trim
              rows="7"
              no-resize
              placeholder="..."
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormTextarea,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    //
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
