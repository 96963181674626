var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"modal-new-provider","centered":"","size":"lg","title":"Cadastro de Fornecedor","hide-footer":""},on:{"hidden":_vm.hidden},model:{value:(_vm.showLocal),callback:function ($$v) {_vm.showLocal=$$v},expression:"showLocal"}},[_c('b-overlay',{attrs:{"show":_vm.showOverlay,"rounded":"sm","no-fade":""}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":'required',"name":"Nome Fantasia","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Fantasia","label-for":"trading-name"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"trading-name","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.provider.trading_name),callback:function ($$v) {_vm.$set(_vm.provider, "trading_name", $$v)},expression:"provider.trading_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":'required',"name":"Razão Social","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Razão Social","label-for":"corporate-name"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"corporate-name","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.provider.corporate_name),callback:function ($$v) {_vm.$set(_vm.provider, "corporate_name", $$v)},expression:"provider.corporate_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required|cpfCnpj|checkDoubleProviderIdentification:null","name":"CNPJ/CPF","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CNPJ/CPF","label-for":"identification-one"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"identification-one","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.provider.identification1),callback:function ($$v) {_vm.$set(_vm.provider, "identification1", $$v)},expression:"provider.identification1"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome do Representante","label-for":"contact-name"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"contact-name","type":"text"},model:{value:(_vm.provider.contact_name),callback:function ($$v) {_vm.$set(_vm.provider, "contact_name", $$v)},expression:"provider.contact_name"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email-edit"}},[_c('b-form-input',{attrs:{"id":"email-edit","type":"email","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.provider.email),callback:function ($$v) {_vm.$set(_vm.provider, "email", $$v)},expression:"provider.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Celuar","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Celular","label-for":"provider-mobile-number"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-#####', '(##) # ####-####']),expression:"['(##) ####-#####', '(##) # ####-####']"}],attrs:{"id":"provider-mobile-number","state":errors.length > 0 ? false : null},model:{value:(_vm.provider.phoneNumber),callback:function ($$v) {_vm.$set(_vm.provider, "phoneNumber", $$v)},expression:"provider.phoneNumber"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fixo","label-for":"contact-phone-1"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-#####', '(##) # ####-####']),expression:"['(##) ####-#####', '(##) # ####-####']"}],attrs:{"id":"contact-phone-1"},model:{value:(_vm.provider.secondPhoneNumber),callback:function ($$v) {_vm.$set(_vm.provider, "secondPhoneNumber", $$v)},expression:"provider.secondPhoneNumber"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Plano de Contas","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Plano de Contas","label-for":"account-plan"}},[_c('v-select',{attrs:{"input-id":"account-plan","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"options":_vm.accountPlans,"clearable":false,"searchable":false,"placeholder":"Selecione"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Nenhum resultado encontrado. ")]}}],null,true),model:{value:(_vm.provider.account_plan_id),callback:function ($$v) {_vm.$set(_vm.provider, "account_plan_id", $$v)},expression:"provider.account_plan_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" Este campo é obrigatório ")])],1)]}}],null,true)})],1)],1),_c('b-tabs',{staticClass:"mt-1",attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"MapPinIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Endereço")])]},proxy:true}],null,true)},[_c('provider-modal-tab-address',{attrs:{"user-data":_vm.provider}})],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Informações Adicionais")])]},proxy:true}],null,true)},[_c('provider-modal-tab-add-info',{attrs:{"data":_vm.provider}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}}),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-button',{attrs:{"variant":"outline-secondary","block":""},on:{"click":_vm.hidden}},[_vm._v(" Cancelar ")])],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":"","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }